$white-transparent: #f5f5f5;
$white: #ffffff;
$white-aluminum: #a3a3a3;
$smoky-white: #f8f8f8;
$smoky-white_2: #f8f7f5;
$smoky-white3: #f1f1f1;
$periwinkle-crayola: #f0f0f4;
$gainsborough: #e0e0e0;
$light-gray: #b8b8b8;
$light-telegray: #cccccc;
$telegray: #8f8f8f;
$gainsborough: #e0e0e0;
$gray: #7a7a7a;
$grey-78: #787880;
$black: #151b1a;
$smoky-white: #ececec;
$blackish-blue: #151b1acc;

$dark-bluishGreen: #004d3d;
$dark_yellow-green: #4ba93c;
$mountain-meadow: #2dc36d;
$very_dark-greenish-blue: #02180f;
$green-34: #34c759;

$intense-redOrange: #fdae5c;

$padding-headerBlock: 12px 12px 8px 12px;

$saffron-yellow: #f6d743;
$macaroni-cheese: #ffbc75;
$yellowish-white: #fee6ba;

$blue-crayola: #6dc4ea;

$deep_yellow-pink: #f33e3c;
$slate-gray: #2c3e50;
$periwinkle-crayola: #f0f0f4;
$red: #f33e3c;
$pearly-blackberry: rgba(120, 120, 128, 0.16);
