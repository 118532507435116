$z-0: 0;
$z-1: 1;
$z-5: 5;
$z-10: 10;
$z-99: 99;
$z-100: 100;
$z-filter-modal: 1500;
$z-popup-modal: 1012;
$z-wrapper-modal: 1011;
$z-leave-feedback-modal: 1012;
$z-mobile-navigation: 800;

$navigation-mobile: 1010;
$popup-navigation-mobile: 1000;
$modal-GEO: 1013;
$modal-farmer-registration: 1013;
$modal-authorization: 1013;
$modal-delivery-day: 1013;
$modal-delete-basket: 1012;
$modal-certificate: 1013;

$header-sticky: 500;
$search-mobile: 900;
$search-desktop: 999;

$search-page-filter: 1500;
$product-card-quantity: 100;

$popup-menu: 499;
$popup-modal: 1011;
