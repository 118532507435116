@import '../../../styles/colors_v2.scss';
@import '../../../styles/breakpoints.scss';
@import 'src/styles/z_index';

.wrapper {
  background-color: $white;
  width: 100%;
  z-index: $navigation-mobile;
  position: fixed;
  right: 0%;
  top: 0;
  height: 100%;
  overflow-y: scroll;
  max-width: 324px;
  padding-bottom: 32px;

  @media (min-width: $sm) {
    display: none;
  }
}

.popupMenu {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1002;
  background-color: rgba(0, 0, 0, 0.4);

  @media (min-width: $sm) {
    display: none;
  }
}

.deliveryPlaceBlock {
  padding: 8px 16px;
  background-color: $white-transparent;
  display: flex;
  align-items: c;
}

.deliveryPlaceInfo {
  font-size: 14px;
  max-width: 188px;
  width: 100%;
  margin-left: 8px;
  line-height: 19px;
}

.deliveryPlaceInfoGray {
  color: $telegray;
}

.deliveryPlaceInfoWeight {
  font-weight: 600;
}

.mobileNavigation {
  padding: 0 14px;
  margin-top: 16px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.closeButton {
  padding: 0;
}

.searchInput {
  margin-top: 16px;
  border-radius: 12px;
  border: 1px solid #e0e0e0;
}

.catalogButton {
  width: 100%;
  background-color: $white-transparent;
  border-radius: 6px;
  margin-top: 6px;
  padding: 11px 0;

  span {
    margin-left: 4px;
  }
}

.promotionsDiscounts {
  display: flex;
  align-items: center;
}

.promotionsDiscountsButton {
  box-sizing: border-box;
  width: 100%;
  margin-top: 8px;
  background-color: $white-transparent;
  max-height: 37px;
  border-radius: 6px;
  display: flex;
  padding: 11px 0;
  align-items: center;
  justify-content: center;

  span {
    margin-left: 4px;
  }
}

.promotionsButton {
  color: $dark_yellow-green;
  margin-right: 8px;
}

.discountsButton {
  color: $deep_yellow-pink;
}

.menuTitle {
  font-weight: 500;
  font-size: 12px;
  color: $telegray;
  margin-top: 24px;
  padding-bottom: 4px;
  border-bottom: 1px solid $white-transparent;
}

.menuItem {
  width: 100%;
}

.menuItemButton {
  padding: 0;
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

.logOutAccount {
  padding-bottom: 20px;
  margin-top: 64px;
  color: $telegray;

  span {
    margin-left: 8px;
  }
}

.inputBlock {
  position: relative;
}

.lastMenuItem {
  padding-bottom: 20px;
}

.searchButton {
  position: absolute;
  top: 12.5px;
  right: 2px;
  opacity: 0.5;
}

.clearInputValue {
  position: absolute;
  top: 16.5px;
  right: 5px;
  opacity: 0.5;
}
