@import 'src/styles/breakpoints';

.layoutWrapper {
  position: relative;
  min-height: 100vh;
}

.footerWrapper {
  width: 100%;
}
