@import 'src/styles/colors';
@import 'src/styles/breakpoints';

.content {
  padding: 100px 40px;

  @media (max-width: ($lg - 1)) {
    padding: 100px 20px;
  }
}

.wrap {
  max-width: 413px;
  margin: 0 auto;
  text-align: center;
}

.title {
  margin: 20px 0 15px;
}

.text {
  font-size: 18px;
  line-height: 150%;

  @media (max-width: ($lg - 1)) {
    font-size: 14px;
  }
}

.mainBtn {
  margin-top: 30px;

  svg {
    margin-left: 10px;
  }

  @media (max-width: ($lg - 1)) {
    height: 50px;
  }
}
